import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import i18n from './language/index.js'

Vue.use(ElementUI);

let baseUrl = 'https://www.gpd-gene.com';

axios.defaults.baseURL = `${baseUrl}/lixin`;

Vue.config.productionTip = false;

Vue.filter('pathCompletion', (url) => {
	let path = '';
	if (url) {
		if (url.indexOf('http') == -1 && url.indexOf('https') == -1) {
			path = baseUrl + url;
		} else {
			path = url;
		}
		if (url.indexOf('/static') != -1) {
			path = url;
		}
	}
	return path;
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
