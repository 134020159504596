<template>
  <div>
	<headerCustom></headerCustom>
    <router-view></router-view>
	<footerCustom></footerCustom>
  </div>
</template>

<script>
  import headerCustom from './components/headerCustom';
  import footerCustom from './components/footerCustom';

  export default {
      components: {
        headerCustom,
		footerCustom
      }
  }
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
	font-family: system-ui,-apple-system,BlinkMacSystemFont,segoe ui,Roboto, Helvetica,Arial, sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
  }
  body {
    background-color: #FFFFFF;
	font-size: 16px;
	color: #333333;
  }
  .main {
		padding-bottom: 60px;
	}
  span {
		display: inline-block;
	}
  a {
	text-decoration: none;
	display: inline-block;
	color: #333333;
  }
  ul {
    list-style-type: none;
  }
  button {
	  border: 0;
	  display: block;
	  cursor: pointer;
  }
  div, span {
	  word-break: break-all;
  }
  .ellipsis {
	overflow:hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow:ellipsis;
  }
  .ellipsis2 {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;					
	-webkit-box-orient: vertical;
  }
  .ellipsis3 {
  	text-overflow: -o-ellipsis-lastline;
  	overflow: hidden;
  	text-overflow: ellipsis;
  	display: -webkit-box;
  	-webkit-line-clamp: 3;
  	line-clamp: 3;					
  	-webkit-box-orient: vertical;
  }
</style>
