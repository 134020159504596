import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component:  () => import('../views/index.vue')
  },
  {
    path: '/serve',
    name: 'serve',
    component:  () => import('../views/serve.vue')
  },
  {
    path: '/serveDetail',
    name: 'serveDetail',
    component:  () => import('../views/serveDetail.vue')
  },
  {
    path: '/news',
    name: 'news',
    component:  () => import('../views/news.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component:  () => import('../views/newsDetail.vue')
  },
  {
    path: '/regard',
    name: 'regard',
    component:  () => import('../views/regard.vue')
  },
  {
    path: '/team',
    name: 'team',
    component:  () => import('../views/team.vue')
  },
  {
    path: '/certification',
    name: 'certification',
    component:  () => import('../views/certification.vue')
  },
  {
    path: '/product',
    name: 'product',
    component:  () => import('../views/product.vue')
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component:  () => import('../views/productDetail.vue')
  },
  {
    path: '/detection',
    name: 'detection',
    component:  () => import('../views/detection.vue')
  },
  {
    path: '/detectionDetail',
    name: 'detectionDetail',
    component:  () => import('../views/detectionDetail.vue')
  },
  {
    path: '/detectionReport',
    name: 'detectionReport',
    component:  () => import('../views/detectionReport.vue')
  },
  {
    path: '/join',
    name: 'join',
    component:  () => import('../views/join.vue')
  },
  {
    path: '/joinDetail',
    name: 'joinDetail',
    component:  () => import('../views/joinDetail.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component:  () => import('../views/contact.vue')
  },
  {
    path: '/richText',
    name: 'richText',
    component:  () => import('../views/richText.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: './',
  routes
})

export default router
