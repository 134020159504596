import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = {
    locale: localStorage.getItem('lang') || 'zh',
    messages: {
		zh: require('./local_zh'),
		en: require('./local_en')
    }
}

export default i18n;