module.exports =  {
	hotline: '热线电话',
	home: '首页',
	regard: '关于',
	product: '产品中心',
	detection: '检测中心',
	other: '其他',
	corporateInformation: '公司信息',
	managementTeam: '管理团队',
	honorQualification: '荣誉资质',
	serviceIntroduction: '服务介绍',
	projectService: '项目服务',
	surveyReport: '调查报告',
	joinUs: '加入我们',
	contactUs: '联系我们',
	siteNavigation: '网站导航',
	websiteHome: '网站首页',
	corporateFax: '公司传真',
	serviceHotline: '服务热线',
	companyAddress: '公司地址',
	followUs: '扫一扫，关注我们',
	wechatApp: '扫一扫，进入小程序',
	icp: '备案号',
	aboutJinpujia: '关于金普嘉',
	development: '人体基因诊断与治疗技术开发',
	majorProducts: '重点产品',
	more: '更多',
	pressCenter: '新闻中心',
	learnMore: '了解更多',
	friendlyLinks: '友情链接',
	yourCurrentLocation: '您当前的位置',
	primaryCategory: '一级类目',
	secondaryCategory: '二级类目',
	tertiaryCategory: '三级类目',
	noData: '暂无数据',
	projectDomain: '项目领域',
	projectDisease: '项目疾病',
	projectName: '项目名称',
	selectProjectDomain: '请选择项目领域',
	selectProjectDisease: '请选择项目疾病',
	selectProjectName: '请输入项目名称',
	search: '搜索',
	includeItem: '包含项目',
	productDetail: '产品详情',
	inspectionReportQuery: '检测报告查询',
	idNumber: '身份证号',
	detectionNumber: '检测编号',
	enterIdNumber: '请输入身份证号',
	enterDetectionNumber: '请输入检测编号',
	noteIdNumber: '备注：请输入身份证号或者检测编号查看检测报告',
	query: '查询',
	operation: '操作',
	viewReport: '查看报告',
	education: '学历',
	recruit: '招聘',
	date: '日期',
	viewDetail: '查看详情',
	jobTitle: '职位',
	immediatelyDeliver: '立即投递',
	phone: '电话',
	email: '邮箱',
	address: '地址',	
	businessHours: '营业时间',
	contactQRcode: '联系二维码',
	businessLicenseInformation: '营业执照信息',
}