<template>
    <div class="header-custom">
        <div class="navbar">
            <div class="navbar-l">
				<router-link to="/" class="app">
					<div class="logo">
						<el-image class="img" :src="detail.logo | pathCompletion" fit="cover"></el-image>
					</div>
					<div class="name">
						<template v-if="language == 'zh'">
							{{ detail.qiYeName }}
						</template>
						<template v-if="language == 'en'">
							{{ detail.qiYeNameYw }}
						</template>
					</div>
				</router-link>
                <div class="language">
                    <div class="language-h zuo" @click="languageChange('zh')" :class="language == 'zh' ? 'active' : ''">
                        CN
                    </div>
                    <div class="language-h you" @click="languageChange('en')" :class="language == 'en' ? 'active' : ''">
                        EN
                    </div>
                </div>
            </div>
            <div class="navbar-r">
                <div class="tabs">
                    <div class="tabs-item" v-for="(item, index) in tabs" :key="index" @click="tabsClick(item, index)" :class="index == current ? 'active' : ''">
						{{ $t(item.name) }}
                    </div>
                </div>
                <div class="phone">
                    {{ $t('hotline') }}：{{ detail.servicePhone }}
                </div>
            </div>
        </div>
		<div class="box box1" v-if="show1">
			<div class="box-item" @click="boxClick('/regard')">
				<div class="item-t">
					{{ $t('corporateInformation') }}
				</div>
				<div class="item-b">
					<el-image class="img" :src="info.gongSiImg | pathCompletion" fit="cover"></el-image>
				</div>
			</div>
			<div class="box-item" @click="boxClick('/team')">
				<div class="item-t">
					{{ $t('managementTeam') }}
				</div>
				<div class="item-b">
					<el-image class="img" :src="info.teamImg | pathCompletion" fit="cover"></el-image>
				</div>
			</div>
			<div class="box-item" @click="boxClick('/certification')">
				<div class="item-t">
					{{ $t('honorQualification') }}
				</div>
				<div class="item-b">
					<el-image class="img" :src="info.rongYuImg | pathCompletion" fit="cover"></el-image>
				</div>
			</div>
		</div>
		<div class="box box1" v-if="show2">
			<div class="box-item" @click="boxClick('/serve')">
				<div class="item-t">
					{{ $t('serviceIntroduction') }}
				</div>
				<div class="item-b">
					<el-image class="img" :src="info.fwImg | pathCompletion" fit="cover"></el-image>
				</div>
			</div>
			<div class="box-item" @click="boxClick('/detection')">
				<div class="item-t">
					{{ $t('projectService') }}
				</div>
				<div class="item-b">
					<el-image class="img" :src="info.xmImg | pathCompletion" fit="cover"></el-image>
				</div>
			</div>
			<div class="box-item" @click="boxClick('/detectionReport')">
				<div class="item-t">
					{{ $t('surveyReport') }}
				</div>
				<div class="item-b">
					<el-image class="img" :src="info.bgImg | pathCompletion" fit="cover"></el-image>
				</div>
			</div>
		</div>
		<div class="box box2" v-if="show3">
			<div class="box-item" @click="boxClick('/join')">
				<div class="item-t">
					{{ $t('joinUs') }}
				</div>
				<div class="item-b">
					<el-image class="img" :src="info.jrwmImg | pathCompletion" fit="cover"></el-image>
				</div>
			</div>
			<div class="box-item" @click="boxClick('/contact')">
				<div class="item-t">
					{{ $t('contactUs') }}
				</div>
				<div class="item-b">
					<el-image class="img" :src="info.lxwmImg | pathCompletion" fit="cover"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
  import axios from "axios";
	
  export default {
      name: 'headerCustom',
	  data() {
		  return {
			  detail: {},
			  info: {},
			  current: 0,
			  tabs: [
				  {
					name: 'home'
				  },
				  {
				  	name: 'regard'
				  },
				  {
				  	name: 'product'
				  },
				  {
				  	name: 'detection'
				  },
				  {
				  	name: 'other'
				  },
			  ],
			  show1: false,
			  show2: false,
			  show3: false,
		  }
	  },
	  computed: {
	  	language() {
	  		return this.$i18n.locale;
	  	},
	  },
	  watch: {
		  $route(val) {
			  if (val.path == '/' || val.path == '/news' || val.path == '/newsDetail') {
				  this.current = 0;
			  } else if (val.path == '/regard' || val.path == '/team' || val.path == '/certification') {
				  this.current = 1;
			  } else if (val.path == '/product' || val.path == '/productDetail') {
				  this.current = 2;
			  } else if (val.path == '/serve' || val.path == '/serveDetail' || val.path == '/detection' || val.path == '/detectionDetail' || val.path == '/detectionReport') {
				  this.current = 3;
			  } else if (val.path == '/join' || val.path == '/joinDetail' || val.path == '/contact') {
				  this.current = 4;
			  } else {
				  this.current = 0;
			  }
		  },
	  },
	  mounted() {
		  this.getQiYeConfig();
		  this.getPcImgConfig();
	  },
	  methods: {
		  //获取企业信息
		  getQiYeConfig() {
			  axios({
				url: '/pc/getQiYeConfig',
				method: 'get'
			  }).then(res => {
				if (res.data.result == 0) {
					this.detail = res.data.data;
				}
			  });
		  },
		  
		  //获取PC图片配置
		  getPcImgConfig() {
			  axios({
				url: '/pc/getPcImgConfig',
				method: 'get'
			  }).then(res => {
				if (res.data.result == 0) {
					this.info = res.data.data;
				}
			  });
		  },
		  
		  //tabs点击
		  tabsClick(item, index) {
			  this.current = index;
			  this.show1 = false;
			  this.show2 = false;
			  this.show3 = false;
			  if (this.current == 0) {
				  let url = '/';
				  if (url != this.$route.path) {
					  this.$router.push(url);
				  }
			  } else if (this.current == 1) {
				 this.show1 = true;
			  } else if (this.current == 2) {
				  let url = '/product';
				  if (url != this.$route.path) {
				  	this.$router.push(url);
				  }
			  } else if (this.current == 3) {
				  this.show2 = true;
			  } else if (this.current == 4) {
				  this.show3 = true;
			  } else {
				  let url = '/';
				  if (url != this.$route.path) {
				  	this.$router.push(url);
				  }
			  }
		  },
		  
		  //其他点击
		  boxClick(url) {
			  if (url != this.$route.path) {
				  this.$router.push(url);
			  }
			  this.show1 = false;
			  this.show2 = false;
			  this.show3 = false;
		  },
	  
		  //语言切换
		  languageChange(language) {
			  this.$i18n.locale = language;
			  localStorage.setItem('lang', language);
		  },
	  }
  }
</script>

<style scoped lang="scss">
    .header-custom {
		height: 80px;
        .navbar {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 80px;
			background-color: #3AD6AC;
			color: #fff;
            display: flex;
			justify-content: center;
			align-items: center;
			z-index: 10000;
			.navbar-l {
				display: flex;
				align-items: center;
				flex-shrink: 0;
				.app {
					display: flex;
					align-items: center;
					.img {
						width: 60px;
						height: 60px;
						display: block;
					}
					.name {
						font-size: 26px;
						font-weight: bold;
						margin-left: 24px;
						color: #fff;
					}
				}
				.language {
					display: flex;
					align-items: center;
					margin-left: 36px;
					.language-h {
						border: 1px solid #FFFFFF;
						padding: 1px 12px;
						font-weight: bold;
						cursor: pointer;
					}
					.language-h.zuo {
						border-radius: 12px 0px 0px 12px;
					}
					.language-h.you {
						border-radius: 0px 12px 12px 0px;
					}
					.language-h.active {
						background-color: #fff;
						color: #3AD6AC;
					}
				}
			}
			.navbar-r {
				display: flex;
				align-items: center;
				flex-shrink: 0;
				margin-left: 157px;
				.tabs {
					display: flex;
					align-items: center;
					font-size: 18px;
					.tabs-item {
						margin-right: 40px;
						color: #fff;
						font-weight: bold;
						cursor: pointer;
					}
					.tabs-item:after {
						content: ' ';
						display: block;
						width: 20px;
						height: 4px;
						background: #FFFFFF;
						border-radius: 2px;
						margin: 0 auto;
						margin-top: 7px;
						visibility: hidden;
					}
					.tabs-item.active:after {
						visibility: visible;
					}
				}
				.phone {
					font-size: 18px;
					margin-bottom: 11px;
					font-weight: bold;
				}
			}
        }
		.box {
			position: fixed;
			z-index: 900;
			top: 80px;
			left: 50%;
			transform: translate(-50%, 0);
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 30px;
			padding-right: 0;
			.box-item {
				margin-right: 30px;
				cursor: pointer;
				.item-t {
					font-size: 20px;
				}
				.item-b {
					margin-top: 20px;
					.img {
						width: 230px;
						height: 130px;
						display: block;
					}
				}
			}
		}
		.box1 {
			margin-left: 280px;
		}
		.box2 {
			margin-left: 330px;
		}
	}
</style>
  