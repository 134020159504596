<template>
    <div class="footer-custom">
       <div class="wang">
			<div class="wang-z">
				<div class="dao">
				   <div class="dao-l">
					   {{ $t('siteNavigation') }}
				   </div>
			   </div>
				<div class="zhan">
					<router-link to="/" class="zhan-h">
						{{ $t('websiteHome') }}
					</router-link>
					<router-link to="/regard" class="zhan-h">
						{{ $t('corporateInformation') }}
					</router-link>
					<router-link to="/team" class="zhan-h">
						{{ $t('managementTeam') }}
					</router-link>
					<router-link to="/certification" class="zhan-h">
						{{ $t('honorQualification') }}
					</router-link>
					<router-link to="/join" class="zhan-h">
						{{ $t('joinUs') }}
					</router-link>
					<router-link to="/contact" class="zhan-h">
						{{ $t('contactUs') }}
					</router-link>
				</div>
			</div>
			<div class="wang-z">
				<div class="dao">
				   <div class="dao-l">
					   {{ $t('contactUs') }}
				   </div>
			   </div>
				<div class="gong">
					<div class="gong-h">
						{{ $t('corporateFax') }}：{{ detail.chuanZhen }}
					</div>
					<div class="gong-h">
						{{ $t('serviceHotline') }}：{{ detail.servicePhone }}
					</div>
					<div class="gong-h">
						{{ $t('companyAddress') }}：
						<template v-if="language == 'zh'">
							{{ detail.address }}
						</template>
						<template v-if="language == 'en'">
							{{ detail.addressYw }}
						</template>
					</div>
				</div>
			</div>
			<!-- <div class="wang-z wu">
				<div class="dao">
				   <div class="dao-l">
				   		{{ $t('contactUs') }}
				   </div>
				</div>
				<div class="sao">
					<div class="sao-h">
						<div class="pic">
							<el-image class="img" :src="detail.gzhImg | pathCompletion" fit="cover"></el-image>
						</div>
						<div class="word">
							{{ $t('followUs') }}
						</div>
					</div>
					<div class="sao-h">
						<div class="pic">
							<el-image class="img" :src="detail.xcxImg | pathCompletion" fit="cover"></el-image>
						</div>
						<div class="word">
							{{ $t('wechatApp') }}
						</div>
					</div>
				</div>
			</div> -->
	   </div>
	   <div class="ban">
		   <div class="ban-w" @click="icpClick">
				<template v-if="language == 'zh'">
					{{ detail.copyright }}
				</template>
				<template v-if="language == 'en'">
					{{ detail.copyrightYw }}
				</template>
				{{ $t('icp') }}：{{ detail.beiAnNumber }}
		   </div>
	   </div>
    </div>
</template>
  
<script>
  import axios from "axios";
	
  export default {
      name: 'footerCustom',
	  data() {
		  return {
			  detail: {},
		  }
	  },
	  computed: {
	  	language() {
	  		return this.$i18n.locale;
	  	},
	  },
	  mounted() {
		  this.getQiYeConfig();
	  },
	  methods: {
		//获取企业信息
		getQiYeConfig() {
			axios({
				url: '/pc/getQiYeConfig',
				method: 'get'
			}).then(res => {
				if (res.data.result == 0) {
					this.detail = res.data.data;
				}
			});
		},
		
		//icp跳转
		icpClick() {
			window.open('https://beian.miit.gov.cn/#/Integrated/index');
		},
	  }
  }
</script>

<style scoped lang="scss">
	.footer-custom {
		background-color: #373737;
		color: #fff;
		.wang {
			display: flex;
			justify-content: center;
			padding: 60px 0;
			border-bottom: 1px solid #5C5C5C;
			.wang-z {
				margin-right: 150px;
				.dao {
					display: flex;
					align-items: center;
					font-weight: bold;
					.dao-l {
						font-size: 20px;
						margin-right: 10px;
					}
					.dao-l:after {
						content: ' ';
						display: block;
						width: 40px;
						height: 4px;
						background: #FFFFFF;
						margin-top: 5px;
					}
				}
				.zhan {
					display: flex;
					flex-wrap: wrap;
					width: 220px;
					padding-top: 10px;
					.zhan-h {
						margin-right: 46px;
						margin-top: 30px;
						color: #fff;
					}
				}
				.gong {
					padding-top: 10px;
					.gong-h {
						margin-top: 30px;
					}
				}
				.sao {
					display: flex;
					align-items: center;
					.sao-h {
						margin-top: 23px;
						margin-right: 57px;
						.pic {
							.img {
								width: 100px;
								height: 100px;
								display: block;
								margin: 0 auto;
							}
						}
						.word {
							margin-top: 10px;
						}
					}
				}
			}
			.wu {
				margin-right: 0;
			}
		}
		.ban {
			padding: 35px 0;
			text-align: center;
			cursor: pointer;
			.ban-w {
				color: #fff;
			}
		}
	}
</style>
  