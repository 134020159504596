module.exports =  {
    hotline: 'Hotline',
	home: 'Home',
	regard: 'Regard',
	product: 'Product',
	detection: 'Detection',
	other: 'Other',
	corporateInformation: 'Corporate Information',
	managementTeam: 'Management Team',
	honorQualification: 'Honor Qualification',
	serviceIntroduction: 'Service Introduction',
	projectService: 'Project Service',
	surveyReport: 'Survey Report',
	joinUs: 'Join Us',
	contactUs: 'Contact Us',
	siteNavigation: 'Site Navigation',
	websiteHome: 'Website Home',
	corporateFax: 'Corporate Fax',
	serviceHotline: 'Service Hotline',
	companyAddress: 'Company Address',
	followUs: 'Scan，Follow Us',
	wechatApp: 'Scan，Wechat App',
	icp: 'ICP',
	aboutJinpujia: 'About Jinpujia',
	development: 'Development of human gene diagnosis and therapy technology',
	majorProducts: 'Major Products',
	more: 'More',
	pressCenter: 'News Center',
	learnMore: 'Learn More',
	friendlyLinks: 'Friendly Links',
	yourCurrentLocation: 'Your current location',
	primaryCategory: 'Primary Category',
	secondaryCategory: 'Secondary Category',
	tertiaryCategory: 'Tertiary Category',
	noData: 'No Data',
	projectDomain: 'Project Domain',
	projectDisease: 'Project Disease',
	projectName: 'Project Name',
	selectProjectDomain: 'Please select Project Domain',
	selectProjectDisease: 'Please select Project Disease',
	selectProjectName: 'Please enter Project Name',
	search: 'search',
	includeItem: 'Include Item',
	productDetail: 'Product Detail',
	inspectionReportQuery: 'Inspection report query',
	idNumber: 'ID number',
	detectionNumber: 'Detection number',
	enterIdNumber: 'Please enter your ID number',
	enterDetectionNumber: 'Please enter the Detection number',
	noteIdNumber: 'Note: Please enter the ID number or test number to view the test report',
	query: 'query',
	operation: 'operation',
	viewReport: 'View report',
	education: 'Education',
	recruit: 'Recruit',
	date: 'Date',
	viewDetail: 'View Detail',
	jobTitle: 'Job',
	immediatelyDeliver: 'Immediately Deliver',
	phone: 'phone',
	email: 'email',
	address: 'address',
	businessHours: 'Business Hours',
	contactQRcode: 'Contact QR code',
	businessLicenseInformation: 'Business license information',
}